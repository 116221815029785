import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Gallery from '../components/Gallery'

import rental01 from '../assets/images/rental/morningside-manor.jpeg'
import rental02 from '../assets/images/rental/parkwood-rosebank.jpeg'

const RS_IMAGES = [
    { id: '1', src: rental01, thumbnail: rental01, caption: 'Morningside Manor', description: 'Conveniently located in the the heart of Morningside Manor. Walking distance to Pavilion Shopping Centre and The Wedge Shopping Centre. A house share that stands on an erf size of more than 4000sqm, has more than 6 bedrooms to choose from. Can be en-suite or bedrooms only. Can choose to have a private kitchen or a common kitchen to share. Tenants also have the option to rent a garage for their cars.'},
    { id: '2', src: rental02, thumbnail: rental02, caption: 'Parkwood Rosebank', description: 'Conveniently located in the hub of Rosebank. Easy access to Gautrain Rosebank Station, Rosebank Mall, and the top rated restaurants in our neighborhood Parktown North. A house share that stands on an erf size of more than 2000sqm, has more than 6 bedrooms to choose from. Can be en-suite or bedrooms only. Can choose to have a private kitchen or a common kitchen to share.'}
];
const siteTitle = "House Rentals in Johannesburg | The Fu Foundation"
const siteDescription = "Affordable house rentals in Johannesburg."


const Rental = (props) => (
    <Layout>
        <Helmet>
            <title>{siteTitle}</title>
            <meta name="description" content={siteDescription} />
        </Helmet>

        <div id="main" className="alt">
            <section id="rental-stocks">
                <div className="inner">
                    <header className="major">
                        <h1>Rental Stocks</h1>
                    </header>

                    <Gallery images={RS_IMAGES.map(({ id, src, thumbnail, caption, description }) => ({
                        src,
                        thumbnail,
                        caption,
                        description
                    }))} />

                </div>
            </section>

{/* DEVNOTE: refactor this later, remove duplicate code */}
            <section id="contact-us">
                <h2>Get In Touch</h2>
                <p>Should you require additional information regarding our developments, rental stocks, or the exporting of minerals, please feel free to send us an email. Alternatively, complete the form below and we will get back to you.</p>
                <div className="row">
                    <div className="8u 12u$(small)">
                        <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
                            <input type="hidden" name="bot-field" />
                            <div className="row uniform 50%">
                                <div className="6u 12u$(xsmall)"><input type="text" name="name" id="name" placeholder="Name" /></div>
                                <div className="6u 12u$(xsmall)"><input type="email" name="email" id="email" placeholder="Email" /></div>
                                <div className="12u"><textarea name="message" id="message" placeholder="Message" rows="4"></textarea></div>
                            </div>
                            <br/>
                            <ul className="actions">
                                <li><input type="submit" value="Send Message" /></li>
                                <li><input type="reset" value="Clear" /></li>
                            </ul>
                        </form>
                    </div>
                    <div className="4u 12u$(small)">
                        <ul className="labeled-icons">
                            <li>
                                <h3 className="icon fa-home"><span className="label">Address</span></h3>
                                13 Chester Rd.<br />
                                Parkwood, Johannesburg<br />
                                South Africa
                            </li>
                            <li>
                                <h3 className="icon fa-envelope-o"><span className="label">Email</span></h3>
                                <a href="mailto:info@thefufoundation.com" target="_top">info@thefufoundation.com</a><br/>
                                <a href="mailto:rental@thefufoundation.com" target="_top">rental@thefufoundation.com</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>       
        </div>

    </Layout>
)

export default Rental